(function ($, root, undefined) {
	
	$(function () {
		'use strict';
			if($('body.search').length > 0){
				var filter = $('.filter-search').html();

				$('.feature').remove();
				$('.filter-search').remove();
				$( "#searchresult" ).prepend(filter);
					
				setTimeout(function(){
					showGrade();
				}, 200);
			}

			$(document).ready(function(){
				$('form.search').submit(function(e) { // run the submit function, pin an event to it
					var s = $( this ).find("#search-field").val($.trim($( this ).find("#search-field").val())); // find the #s, which is the search input id and trim any spaces from the start and end
					if (!s.val()) { // if s has no value, proceed
						e.preventDefault(); // prevent the default submission
						$('#search-field').focus(); // focus on the search input
					}
				});
			});
	});

	
	
})(jQuery, this);
