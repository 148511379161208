(function ($, root, undefined) {

	$(function () {

		'use strict';

			window.urlParam = function(name, w){
			    w = w || window;
			    var rx = new RegExp('[\&|\?]'+name+'=([^\&\#]+)'),
			        val = w.location.search.match(rx);
			    return !val ? '':val[1];
			}

		if($('body.fragor-och-svar').length > 0){
			if(window.location.href.indexOf("?") > -1) {
			  	var question = urlParam('question');
			  	var question = question.slice(1, -1);

	      		var div = $('.' + question);
				event.preventDefault();
				var offset = $(div).offset();
			    var scrolltoY = offset.top;
			    var scrolledSubnavHeight;
			    var subnav = $('.subnavigation');

			    if($('.subnavigation').hasClass('scrolled')){
			    	scrolledSubnavHeight = subnav.height();
			    } else{
			    	subnav.addClass('scrolled');
			    	scrolledSubnavHeight = subnav.height();
			    	subnav.removeClass('scrolled');
			    }

				$('html,body').animate({ scrollTop: scrolltoY - scrolledSubnavHeight - 80 }, 500);

				$('.' + question).addClass('active');
			}

			$('body').on('click', '.inline-link-list li', function(event){
				var div = $('#' + $(this).attr('data'));
				event.preventDefault();
				var offset = $(div).offset();
			    var scrolltoY = offset.top;
			    var scrolledSubnavHeight;
			    var subnav = $('.subnavigation');

			    if($('.subnavigation').hasClass('scrolled')){
			    	scrolledSubnavHeight = subnav.height();
			    } else{
			    	subnav.addClass('scrolled');
			    	scrolledSubnavHeight = subnav.height();
			    	subnav.removeClass('scrolled');
			    }

				$('html,body').animate({ scrollTop: scrolltoY - scrolledSubnavHeight - 80 }, 500);

			});

			$('body').on('click', '.questionblock li .singlequestion', function(event){
				$(this).parent('li').toggleClass('active');
			});



			$(window).scroll(function (event) {
		    	var scroll = $(window).scrollTop();
		    	console.log(scroll);
				var top = $('.subnavigation').offset().top - 60;
				var bottom = ($('.subnavigation').offset().top + $('.subnavigation').height()) - 300;
				if(scroll >= top){
			    	$('.subnavigation').addClass('active')
			    	var snHeight = $('.subnavigation').outerHeight();
			    	$('.scrollheight').height(snHeight);
		    	}
				if(scroll < 140){
			    	$('.subnavigation').removeClass('active');
			    	$('.scrollheight').height(0);
		    	}
			});

		}

	});

})(jQuery, this);
