(function ($, root, undefined) {
	
	$(function () {
		'use strict';
		
		function goToByScroll(div){
		    var offset = $(div).offset();
		    var scrolltoY = offset.top;
			if($('body.no-touch').length > 0){
				$('html,body').animate({ scrollTop: scrolltoY - 120 }, 500);
			}
			else{
				$('html,body').animate({ scrollTop: scrolltoY - 160 }, 500);
			}
		}

		$('body').on('change', '.multi', function(event){
			$('.MultiFile-remove').each(function(){
				$(this).html('<button>Ta bort</button>')
			})
		})
			
		$('body').on('submit', '.ninja-forms-cont', function(event){
			goToByScroll('#textsida');
		})
			
		$('body').on('change', '.options', function(event){
			showFormContent();
		});

		$('body').on('click', '.js-showformcontent', function(event){
			event.preventDefault();
			showFormContent();
		});

		$('#contactPage form').on('submit', function(){
			goToByScroll($('.formholder'));
		});

		function showFormContent(){
			$('.nf-form-cont').hide();
			$('.nf-response-msg').html('');
			if($('.options').val() == 'question'){
				$('#nf-form-7-cont').show();
				$('#nf-form-7-cont').find('form').first().show();
			}
			if($('.options').val() == 'reclamation'){
				$('#nf-form-5-cont').show();
				$('#nf-form-5-cont').find('form').first().show();
			}
			if($('.options').val() == '-' || $('.options').val() == 'rest' ){
				$('#nf-form-8-cont').show();
				$('#nf-form-8-cont').find('form').first().show();
			}
		}
		showFormContent()
		if($('body.kontakt').length > 0){
			$('.options').each(function(){
				$(this)[0].selectedIndex=0;
			});
		}
	});
	
})(jQuery, this);
