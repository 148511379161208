(function ($, root, undefined) {
	
	$(function () {
		
		'use strict';

			(window.onpopstate = function () {
				setTimeout(function(){
					$('.video-container').each(function(){
						$(this).find('iframe').attr('src', $(this).find('iframe').attr('src'));
					});
				}, 300);
			})();
		
			$('.navigation-button', '#nav-btn-wrapper').on('click', function(event){
				var $obj = $(this),
					$slider = $('#slider'),
					slideId = $obj.attr('id').split("_")[1];

				if($obj.hasClass('active')) {
					return;
				}

				if($slider.hasClass('autoplay')){
					clearInterval(imageLoop);
				}
				
				$('.video-container').each(function(){
					var src = $(this).find('iframe').attr('src');
					if(src.indexOf('autoplay') > -1) {
						src = src.replace("autoplay=1", "autoplay=0");
						$(this).find('iframe').attr('src', src);
					}
				});

				var $existingActive = $('#nav-btn-wrapper').find('.active');
				$existingActive.removeClass('active');
				$obj.addClass('active');
				$slider.find('.visable').removeClass('visable');
				$('#' + slideId).addClass('visable');
			});

			$('body').on('click', '.autoplay', function(event){
					clearInterval(imageLoop);
				});

			function goToByScroll(div){
				var offset = $(div).offset();
				var scrolltoY = offset.top;
				if($('body.no-touch').length > 0){
					$('html,body').animate({ scrollTop: scrolltoY - 120 }, 100);
				}
				else{
					$('html,body').animate({ scrollTop: scrolltoY - 160 }, 100);
				}
			    
			}
			
			$('body').on('click', '.next', function(event){
				nextImg();
				goToByScroll('.schoolStep');
			});
			
			$('body').on('click', '.prev', function(event){
				prevImg();
				goToByScroll('.schoolStep');
			});
			
			function nextImg(){
					var visable =  $('.visable').attr('id');
					var $currentBlock = $('#' + visable);
					var $next = $currentBlock.next('.slide.section');
					var $nextLi = $('#li_' + visable).next('.navigation-button');
					$('#nav-btn-wrapper .active').removeClass('active');
					$('.video-container').each(function(){
						var src = $(this).find('iframe').attr('src');
						if(src.indexOf('autoplay') === -1){

						} else{
							src = src.replace("autoplay=1", "autoplay=0");
							$(this).find('iframe').attr('src', src);
						}
					});
					if($next.length){
						$currentBlock.removeClass('visable')
						$next.addClass('visable');
						$currentBlock.hide("slide", { direction: "left" }, 500);
						$next.show("slide", { direction: "right" }, 500);
						$nextLi.addClass('active');
					}
					else{
						// $('#' + visable).hide("slide", { direction: "left" }, 500);
						// $('#' + visable).removeClass('visable')
						// $('#0').show("slide", { direction: "right" }, 500);
						// $('#0').addClass('visable');
						
						
						// $('#nav-btn-wrapper .0').addClass('active');
					}
				}
			
			function prevImg(){
					var visable =  $('.visable').attr('id');
					var $currentBlock = $('#' + visable);
					var $prev = $currentBlock.prev('.slide.section');
					var $prevLi = $('#li_' + visable).prev('.navigation-button');
					$('#nav-btn-wrapper .active').removeClass('active');
					if($prev.length){
						$currentBlock.removeClass('visable')
						$prev.addClass('visable');
						$prevLi.addClass('active');
						$currentBlock.hide("slide", { direction: "right" }, 500);
						$prev.show("slide", { direction: "left" }, 500);
					} else {
						// $('#' + visable).hide("slide", { direction: "right" }, 500);
						// $('#' + visable).removeClass('visable')
						// $('#0').show("slide", { direction: "left" }, 500);
						// $('#0').addClass('visable');
						// $('#nav-btn-wrapper .0').addClass('active');
					}
				}
				
			if($('#slider').hasClass('autoplay')){
		   		var timer = null, 
		   		interval = 3000,
		   		imageLoop = setInterval(function () {
		        	nextImg()
			  }, interval); 
			}
			
			  $( "#slider .slide" ).each(function( index ) {
				  if($("#slider").children().children('li').length > 1 ) {
					 if($('body.bakskolan').length > 0){
					  }
					  else{
					  		$(this).attr('id',JSON.stringify(index));
					  		$('#nav-btn-wrapper').append('<button class="'+ JSON.stringify(index) +' navigation-button"></button>')
					  }
					  if(index == 0){
						var search = window.location.search;
						if (search == '') {
							$(this).addClass('visable');
							$('#nav-btn-wrapper').children('.navigation-button').first().addClass('active');
						}
					  }
				  }
				  else{
					if($('#slider').hasClass('autoplay')){
						clearInterval(imageLoop);
					}
					$(this).addClass('visable');
				  }
				  
			});

		});
})(jQuery, this);
