(function ($, root, undefined) {
	$(function () {
		'use strict';
		var search = window.location.search;
		if(search !== '' && $('body').hasClass('bakskolan')) {
			var queryString = search.split("?");
			var queryParams = [];
			queryString[1].split('&').forEach(function (entry) {
				var queryParamEntry = entry.split("=");
				queryParams[queryParamEntry[0]] = queryParamEntry[1];
			});
			if (!queryParams['step'] === undefined) {
				return;
			}
			var step = queryParams['step'];
			// $('#slider ul .slide:first-child').hide();
			$('.visable').removeClass('visable');
			$('.link-to-step .active').removeClass('active');
			$('#li_' + step ).addClass('active')
			$('#' + step).addClass('visable');
		} else {
			$('#nav-btn-wrapper').children('.navigation-button').first().addClass('active');
		}
	});
})(jQuery, this);
