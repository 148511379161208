(function ($, root, undefined) {
    $(function () {
        'use strict';

        var $recipeBlock = $('#recipelisting');
        var filtering = [];
        var $ajaxLoader = $('.ajaxloading');
        filtering['activeFilters'] = []; //Filter som är aktiverade
        filtering['activeTotalTime'] = 5000; //Baktid
        filtering['activeSorting'] = 'date'; //Vilken sortering – datum, betyg etc
        filtering['newRequest'] = true; //Om blocket ska tömmas och sedan fyllas med ny sökning
        filtering['nonAjaxAmount'] = 12;
        if ($('body.search').length > 0) {
            filtering['nonAjaxAmount'] = 5000;
        }
        filtering['nonAjaxPaging'] = 1;
        var nonAjaxAmount = 12;

        if ($('body.search').length > 0) {
            nonAjaxAmount = 5000;
        }
        var mode = 'viewtype2';
        var page = false;
        filtering['paging'] = 2;
        var moreItems = false; //Kolla om vi kan ändra ovanstående variabel, man får fel i javascript annars!
        window.siteURL = 'http://kronjast.se';
        var noParams = true;

        var urlParams;
        (window.onpopstate = function () {
            var match,
                pl = /\+/g, // Regex for replacing addition symbol with a space
                search = /([^&=]+)=?([^&]*)/g,
                decode = function (s) {
                    return decodeURIComponent(s.replace(pl, " "));
                },
                query = window.location.search.substring(1);

            urlParams = {};
            while (match = search.exec(query))
                urlParams[decode(match[1])] = decode(match[2]);
        })();

        function checkGetString() {
            if (urlParams && Object.keys(urlParams).length > 1 && urlParams.time > 0) {
                for (var key in urlParams) {
                    // skip loop if the property is from prototype
                    if (!urlParams.hasOwnProperty(key)) continue;

                    var obj = urlParams[key];
                    noParams = false;

                    if (key == 'time') {
                        filtering['activeTotalTime'] = parseInt(obj);

                        setTimeout(function () {
                            $('.timefilter').find('input').val(filtering['activeTotalTime']);
                            $('input[type="range"]').rangeslider('update', true);
                        }, 1400);

                        filterByTime(parseInt(obj), false);
                    }

                    if (key == 'sort') {
                        filtering['activeSorting'] = obj;
                        handleSortingSelector($('.sort' + obj), false);
                    }

                    if (key.substr(0, 3) == 'cat') {
                        var catid = key.substr(3, key.length);
                        filtering['activeFilters'].push(catid);
                        filterTags(catid, false);
                    }

                    if (key == 'nonajaxpaging') {
                        filtering['nonAjaxPaging'] = parseInt(obj);
                    }

                    if (key == 'newRequest') {
                        if (obj === 'false') {
                            filtering['newRequest'] = false;
                        } else {
                            filtering['newRequest'] = true;
                        }
                    }

                    if (key == 'set') {
                        filtering['paging'] = parseInt(obj);
                    }

                }

                if ($('.ajax-on#filter').length > 0) {
                    handleAjaxVisibility();
                    sendAjaxRequest(false, true);

                    if (filtering['newRequest']) {
                        filtering['paging'] = 1;
                    }
                }
            }
        }

        function sortNonAjaxResults(sorting, manipulate) {
            var tempArr = [];
            var recCount = 0;

            $recipeBlock.find('li.puffholder').each(function () {
                var blockelement = $(this);
                var date = blockelement.data('date');
                var grade = blockelement.data('grade');
                var name = blockelement.find('.puff h2').text();


                tempArr.push({
                    date: date,
                    grade: grade,
                    name: name,
                    obj: blockelement,
                    html: blockelement.html()
                });

                recCount++;
            });

            if (manipulate) {
                handleAjaxVisibility();
            }

            var resultat = tempArr.slice(0);

            if (sorting == 'date') {
                resultat.sort(function (a, b) {
                    var x = a.date.toLowerCase();
                    var y = b.date.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                });

                resultat.reverse();

            } else if (sorting == 'title') {
                resultat.sort(function (a, b) {
                    var x = a.name.toLowerCase();
                    var y = b.name.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                });

            } else {
                resultat.sort(function (a, b) {
                    return a.grade - b.grade;
                });

                resultat.reverse();
            }


            var ul = $recipeBlock.find('ul.js-heightcontrol');
            ul.html('');
            ul.append('<li class="widthcontrol"></li>');
            for (var i = 0; i < tempArr.length; i++) {
                ul.append(resultat[i].obj);
                if (ul.find('li:last-child').html().length < 1) {
                    ul.find('li:last-child').html(resultat[i].html);
                }
            }
        }

        $('.squares button').on('click', function (e) {
            e.preventDefault();

            mode = $(this).attr('class');

            $('#recipelisting').removeClass().addClass(mode);
            $(this).addClass('selected').siblings().removeClass('selected');
        });

        if ($('.sorteringsfilter').length > 0) {
            $('body').on('click', '.sorteringsfilter', function (e) {
                e.preventDefault();
                $(this).toggleClass('opened');
            });

            $('body').on('click', function (e) {
                if ($('.sorteringsfilter:hover').length < 1) {
                    $('.sorteringsfilter').removeClass('opened');
                }
            });

            $('body').on('click', '.sorteringsfilter .option', function (e) {
                handleSortingSelector($(this), true);
                filtering['paging'] = 1;
            });

        }

        function handleSortingSelector(elem, sendRequest) {
            var $sorteringsfilter = $('.sorteringsfilter');
            var sorteringsText = elem.text();
            $sorteringsfilter.find('.selected').text(sorteringsText);
            elem.addClass('checked').siblings('span').removeClass('checked');
            filtering['activeSorting'] = elem.data('sortid');
            filtering['paging'] = 1;
            filtering['newRequest'] = true;
            //manipulateURL();

            if ($('.ajax-off#filter').length > 0) {
                sortNonAjaxResults(elem.data('sortid'), true);
                if (sendRequest) {
                    showNoOfNonAjaxRecipes(false);
                }
            } else {
                if (sendRequest) {
                    handleAjaxVisibility();
                    sendAjaxRequest(false, false);
                }
            }

            if (sendRequest) {
                manipulateURL();
            }
        }

        function handleAjaxVisibility() {
            $('.zeroresultsheading').removeClass('active');
            $('.zeroresultsheading').css('display', 'none');
            toggleAjaxLoader('show');
            $recipeBlock.find('.heading').fadeOut(100);
            $recipeBlock.find('.puff').addClass('hidden');
        }

        //För att Icke-ajaxkallade receptlistan ska kunna ha "Mer tack"-knapp men ändå aktiv sortering
        function showNoOfNonAjaxRecipes(newRequest) {

            var count = 0;
            var $morebutton = $('button#non-ajax-more');

            $morebutton.removeClass('active');
            setTimeout(function () {
                $('#recipelisting').find('ul.js-heightcontrol').removeClass('opacity');
            }, 700);

            $('#recipelisting').find('ul.js-heightcontrol').find('.puffholder').each(function () {
                var $this = $(this);
                if (count < (nonAjaxAmount * filtering['nonAjaxPaging'])) {

                    if (newRequest) {
                        $this.removeClass('hidden-by-non-ajax');
                    }

                    if ($this.hasClass('hidden') || $this.hasClass('hidden-by-tag') || $this.hasClass('hidden-by-time')) {

                    } else {
                        if ($this.hasClass('hidden-by-non-ajax')) {
                            $this.find('.puff').addClass('hidden');
                        }

                        setTimeout(function () {
                            toggleAjaxLoader('hide');
                            $this.removeClass('hidden-by-non-ajax');
                        }, 400);

                        count++;
                    }

                } else {
                    $this.addClass('hidden-by-non-ajax');
                    if ($this.hasClass('hidden') || $this.hasClass('hidden-by-tag') || $this.hasClass('hidden-by-time')) {

                    } else {
                        $morebutton.addClass('active');
                    }

                }
            });

            setTimeout(function () {
                $('.puffholder .puff.hidden').removeClass('hidden');
            }, 700);

        }

        $('body').on('click', '#non-ajax-more', function () {
            filtering['nonAjaxPaging']++;
            showNoOfNonAjaxRecipes(false);
            manipulateURL();
        });

        function init() {
            if ($('.timefilter').length > 0) {
                $('input[type="range"]').rangeslider({
                    polyfill: false,
                    onSlide: function (position, value) {
                        updateTimeFilterLabel(value);
                    },

                    onSlideEnd: function (position, value) {
                        filterByTime(value, true);
                    }
                });

                if (noParams && $(window).width() < 600) {
                    mode = 'viewtype3';
                }
            }

            if ($('#filter.ajax-off').length > 0 && noParams) {
                handleAjaxVisibility();
                setTimeout(function () {
                    showNoOfNonAjaxRecipes(true);
                    toggleAjaxLoader('hide');
                }, 600);

                setTimeout(function () {
                    $('#non-ajax-more').removeClass('opacity');
                }, 1500);

            }

            window.insertMoreChoicesLink();

            checkGetString();

        }

        $('body').on('click', '#filter .tags li.more', function (e) {
            e.preventDefault();
            $(this).remove();
            $('#filter .tags li.tag').removeClass('hidden');
            $('#filter .tags').addClass('nomaxheight');
            $('#filter .tags').append('<li class="less">Färre val</li>');
        });

        $('body').on('click', '#filter .tags li.less', function (e) {
            e.preventDefault();
            $(this).fadeOut(300);
            $('#filter .tags').removeClass('nomaxheight');
            setTimeout(function () {
                $('#filter .tags li.less').remove();
                window.insertMoreChoicesLink();
            }, 700);
        });

        function filterByTime(value, sendRequest) {
            filtering['paging'] = 1;
            filtering['newRequest'] = true;
            var recipeAmount = $recipeBlock.find('.puffholder').length;
            filtering['activeTotalTime'] = value;
            if ($('.ajax-off#filter').length > 0) {
                var count = 0;
                $recipeBlock.find('.puffholder').each(function () {
                    if ($(this).data('bakingtime') < value) {
                        $(this).removeClass('hidden-by-time');
                    } else {
                        $(this).addClass('hidden-by-time');
                    }

                    count++;

                });

                handleAjaxVisibility();
                filtering['paging'] = 1;
                if (sendRequest) {
                    manipulateURL();
                }

                setTimeout(function () {
                    animatePuffar();
                }, 1200);

                setTimeout(function () {
                    toggleAjaxLoader('hide');
                }, 900);

            } else {
                if (sendRequest) {
                    handleAjaxVisibility();
                    manipulateURL();
                    sendAjaxRequest(false, false);
                }
            }
        }

        function animatePuffar() {
            $recipeBlock.removeClass('opacity');
            var pufftime = 0;
            $recipeBlock.find('.puff.hidden').each(function () {
                var $this = $(this);
                pufftime = pufftime + 50;
                setTimeout(function () {
                    $this.removeClass('hidden');
                }, pufftime);
            });
        }

        function updateTimeFilterLabel(value) {
            var hours = value / 60;
            var text = "Max " + hours + " tim";
            if (hours > 7) {
                text = "8 tim +";
            }
            $('.timefilter .rangeslider__handle .text').text(text);
        }

        $('body').on('click', '#request-more', function (e) {
            $('.startpageajaxloader .loadingicon').show();
            var more = true;
            filtering['newRequest'] = false;
            manipulateURL();
            sendAjaxRequest(more, false);
        });

        function manipulateURL() {
            if (history.pushState) {
                var filtercount = 0;
                var filterString = "";
                if (!window.location.origin) {
                    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
                }
                var thisUrl = window.location.origin + window.location.pathname;

                var query = urlParam('s');
                for (var i = 0; i < filtering['activeFilters'].length; i++) {
                    if (filtercount < 1) {
                        if (query.length > 1) {
                            filterString = "?s=" + query + "&cat" + filtering['activeFilters'][filtercount] + "=true";
                        } else {
                            filterString = "?cat" + filtering['activeFilters'][filtercount] + "=true";
                        }
                    } else {
                        filterString = filterString + "&cat" + filtering['activeFilters'][filtercount] + "=true";
                    }
                    filtercount++;
                }

                if (filterString.length < 1) {
                    if (query.length > 1) {
                        filterString = "?s=" + query + "&time=" + filtering['activeTotalTime'];
                    } else {
                        filterString = "?time=" + filtering['activeTotalTime'];
                    }

                } else {
                    filterString = filterString + "&time=" + filtering['activeTotalTime'];
                }

                filterString = filterString + "&sort=" + filtering['activeSorting'] + "&newRequest=" + filtering['newRequest'] + "&nonajaxpaging=" + filtering['nonAjaxPaging'] + "&set=" + filtering['paging'] + "&mode=" + mode;
                var stateObj = {
                    mode: "reloaded"
                };
                history.replaceState(stateObj, "KronJäst", thisUrl + filterString);
            }
        }

        $('body').on('click', '#filter .tags li.tag', function (e) {
            e.preventDefault();
            filtering['newRequest'] = true;
            handleAjaxVisibility();
            var $this = $(this);

            setTimeout(function () {
                filterTags($this.data('tagid'), true);
            }, 200);

        });

        function filterTags(elementid, manipulate) {
            filtering['activeFilters'] = [];
            filtering['paging'] = 1;
            var $this = $('.tag.cat' + elementid);
            $this.toggleClass('selected');

            if ($('.ajax-off#filter').length > 0) {
                $recipeBlock.find('.hidden-by-tag').each(function () {
                    var hiddenTagCount = 0;
                    if ($(this).hasClass('hidden-by-tag')) {
                        if ($(this).hasClass('hidden-by-tag-' + elementid)) {
                            for (var i = 0; i < filtering['activeFilters'].length; i++) {
                                if ($(this).hasClass('hidden-by-tag-' + filtering['activeFilters'][i])) {
                                    hiddenTagCount++;
                                }
                            }

                            if (hiddenTagCount < 1) {
                                $(this).removeClass('hidden-by-tag').removeClass('hidden-by-tag-' + elementid);
                            } else {
                                $(this).removeClass('hidden-by-tag-' + elementid);
                            }
                        }
                    }
                });

                $('li.selected').each(function () {
                    var x = $(this).data('tagid');
                    filtering['activeFilters'].push(x);
                });
                filterExistingRecipes();

                setTimeout(function () {
                    showNonAjaxResults();
                    toggleAjaxLoader('hide');
                    $recipeBlock.removeClass('opacity');
                    $recipeBlock.find('.puff.hidden').removeClass('hidden');

                }, 800);
            } else {
                $('li.selected').each(function () {
                    var x = $(this).data('tagid');
                    filtering['activeFilters'].push(x);
                });
                if (manipulate) {
                    sendAjaxRequest(false, false);
                }
            }

            if (manipulate) {
                manipulateURL();
                showNoOfNonAjaxRecipes(true);
            }
        }

        function showNonAjaxResults() {
            toggleAjaxLoader('hide');
            setTimeout(function () {
                $recipeBlock.removeClass('opacity');
            }, 300);

            setTimeout(function () {
                zeroResultsCheck();
            }, 800);
        }

        function zeroResultsCheck() {
            var $zeroResulstHeading = $('.zeroresultsheading');
            if ($recipeBlock.find('ul.section.group').height() < 100) {
                $zeroResulstHeading.css('display', 'block');
                $zeroResulstHeading.addClass('active');
                $('#request-more').removeClass('active');
            } else {
                $zeroResulstHeading.css('display', 'none');
                $zeroResulstHeading.removeClass('active');
            }
        }

        function urlParam(name, w) {
            w = w || window;
            var rx = new RegExp('[\&|\?]' + name + '=([^\&\#]+)'),
                val = w.location.search.match(rx);
            return !val ? '' : val[1];
        }

        function sendAjaxRequest(more, multiplePageLoad) {
            var newrequest = filtering['newRequest'];

            if ($('.options .checked').attr('data-sortid') == 'grade') {
                var sortOnGrade = true;
            }
            if (more) {
                newrequest = false;
            }

            if (newrequest) {
                $recipeBlock.addClass('opacity');
            }

            var query = urlParam('query');
            $.ajax({
                type: 'POST',
                url: ajaxurl,
                data: {
                    "action": "startfilter",
                    'grade': sortOnGrade,
                    'filters': filtering['activeFilters'],
                    'time': filtering['activeTotalTime'],
                    'query': query,
                    'sort': filtering['activeSorting'],
                    'newrequest': newrequest,
                    'page': page,
                    'paging': filtering['paging'],
                    'multiplepageload': multiplePageLoad,
                    'useRegularTemplate': true
                },
                success: function (response) {
                    $('.startpageajaxloader .loadingicon').hide();
                    if (newrequest) {
                        filtering['paging']++;
                        setTimeout(function () {
                            toggleAjaxLoader('hide');
                        }, 500);

                        $recipeBlock.addClass('opacity').find('ul').html(response);

                        setTimeout(function () {
                            animatePuffar();
                        }, 100);

                        setTimeout(function () {
                            zeroResultsCheck();
                        }, 2450);
                    } else {
                        filtering['paging']++;

                        setTimeout(function () {
                            toggleAjaxLoader('hide');
                        }, 450);

                        var recipeSection = $recipeBlock.find('ul.section');
                        var scrollTo = recipeSection.offset().top + recipeSection.height();

                        recipeSection.append(response);

                        animatePuffar();

                        setTimeout(function () {
                            $('html, body').animate({
                                scrollTop: scrollTo - 60
                            }, 300);
                        }, 200);
                    }

                    if ($('.last-child').length > 0 || $recipeBlock.find('ul.section.group').height() < 100) {
                        $('#request-more').removeClass('active');
                    } else {
                        $('#request-more').addClass('active');
                    }
                    showGrade();
                }
            });
        }

        function toggleAjaxLoader(action) {
            if (action == 'show') {
                $ajaxLoader.css('display', 'block');
                setTimeout(function () {
                    $ajaxLoader.addClass('visible');
                }, 200);
            } else {
                $ajaxLoader.removeClass('visible');

                setTimeout(function () {
                    $ajaxLoader.css('display', 'none');
                }, 300);
            }
        }

        if (window.location.href.indexOf("?") > -1) {
            var string = window.location + '';
            var pieces = string.split('?');
        }

        //Filtrera redan hämtade recept, när ajax ej behövs
        function filterExistingRecipes() {
            var count = 0;

            $('.filtersection #filter').find('.tags li.selected').each(function () {
                var temptag = $(this).data('tagid');

                $recipeBlock.find('.puffholder').each(function () {
                    var $this = $(this);
                    if (!$this.hasClass('tag' + temptag)) {
                        $this.addClass('hidden-by-tag').addClass('hidden-by-tag-' + temptag);
                    }

                    count++;

                });
            });
        }

        $(document).ready(function () {
            init();
        });

    });

})(jQuery, this);