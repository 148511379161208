(function ($, root, undefined) {

	$(function () {

		'use strict';


		var $body = $('body');
		function print(){
			window.print();
		}
		function IE(v) {
		  return RegExp('msie' + (!isNaN(v)?('\\s'+v):''), 'i').test(navigator.userAgent);
		}

		///* pdf *///

			var getImageFromUrl = function(url, callback) {
				var img = new Image();

				img.onError = function() {
					alert('Cannot load image: "'+url+'"');
				};
				img.onload = function() {
					callback(img);
				};
				img.src = url;
			}



		function createPDF(imgData){
			var doc = new jsPDF();
			var title = $('#title').text();
			var auter = 'Skapat av: ' + $('.author span').text();
			var time = 'Total tid: ' + $('.total_time').text();
			var activetime = 'Aktiv tid: ' + $('.active_time span').text();
			var details = auter + ' | ' + time + ' | ' + activetime;
			var ingredience = $(this).children('div').children('p').text();

			var logo = new Image();

			logo.src = "../../wp-content/themes/kronjast/img/logo.jpg";


			var splitTopTitle= doc.splitTextToSize(title, 80);
			var titleHeight = splitTopTitle.length;
			var line = 110 + (10 * titleHeight);
			var position = 110 + (10 * titleHeight);
			var detailsTop = 100 + (10 * titleHeight);

			doc.addImage(imgData, 'JPG', 10, 10, 190, 80);
			doc.addImage(logo, 'JPG', 12, 12, 15, 15);

			$('.ingredients .subheading').each(function(i, obj) {
				var ingredienseHeader = $(this).text();
				doc.setFontSize(10);
				doc.setFont("helvetica");
				doc.setFontType("bold");
				doc.text(130, position, ingredienseHeader);
				position = position + 6;
				doc.setFontSize(8);
				doc.setFont("helvetica");
				doc.setFontType("normal");

				$($(this).next().children('li')).each(function(i, obj) {
					var splitTitle = doc.splitTextToSize($(this).text(), 70);
					var lineHeight = splitTitle.length;
					doc.text(130, position, splitTitle);
					position = position + (4 * lineHeight) + 1;
					})
			})

			$('.ingredients h3').each(function(i, obj) {
				var ingredienSubHeader = $(this).text();
				doc.setFontSize(8);
				doc.setFont("helvetica");
				doc.setFontType("bold");
				doc.text(130, position + 2, ingredienSubHeader);
				position = position + 3;
				doc.setFontSize(8);
				doc.setFont("helvetica");
				doc.setFontType("normal");
				$($(this).next()).each(function(i, obj) {
					var splitTitle = doc.splitTextToSize($(this).text(), 70);
					var lineHeight = splitTitle.length;
					doc.text(130, position, splitTitle);
					position = position + (3 * lineHeight) + 1;
					})
			})

			doc.setDrawColor(255, 210, 0);
			doc.roundedRect(125, 100 + (10 * titleHeight) , 75, (position  - 110), 3, 3); // empty red square

			/*details */
			doc.setFontSize(28);
			doc.setFont("helvetica");
			doc.setFontType("bold");
			doc.text(10, 105, splitTopTitle);

			doc.setFontSize(8);
			doc.setFont("helvetica");
			doc.setFontType("bold");
			doc.text(10, detailsTop, details);


			$('.list-element').each(function(i, obj) {
			  	var number = (i + 1)  + '. ';
			  	doc.setFontSize(8);
			  	doc.setFont("helvetica");
			  	doc.setFontType("normal");
			  	//doc.text(20, line, number);
			$(this).children('div').children('p').text()

				var splitTitle = doc.splitTextToSize($(this).children('div').children('p').text(), 100);

				var lineHeight = splitTitle.length;
				console.log('lineHeight'+lineHeight)

				doc.text(10, line, number);
				doc.text(15, line, splitTitle);
				line = line + (5 * lineHeight) + 1;
			});
			var title = $('#title').text() + '.pdf';
			doc.save(title);
		}

		if($('body.single-recipes').length > 0){
			var
			 form = $('#recept-print'),
			 cache_width = form.width(),
			 a4  =[ 595.28,  841.89];  // for a4 size paper width and height

		$body.on('click', '#wp_advance_comment_form .showComment', function(event){
				event.preventDefault();
				$('.showComment').addClass('hidden')
				$('.send-comment').addClass('active');
			})
		$body.on('click', '.ingredients', function(event){
			if($(this).hasClass('active')){
				$body.removeClass('disablescroll');
			} else{
				$body.addClass('disablescroll');
				var offset = $('#title').offset();
			    var scrolltoY = offset.top;
				if($(document).scrollTop() < scrolltoY){
					$('html,body').animate({ scrollTop: scrolltoY - 100 }, 500);
				}
			}

			$('.ingredients').toggleClass('active');
		})
		$body.on('click', '.yes-image', function(event){
				window.print();
				$(this).parent().toggleClass('active');
			})

		$body.on('click', '.no-image', function(event){
				$('#slider').addClass('no-image-print');
				window.print();
				$('#slider').removeClass('no-image-print');
				$(this).parent().toggleClass('active');
			})

		$body.on('click', '.btn-wrapper', function(event){
				$(this).toggleClass('active');
			})
      $body.on('click', '.vote-btn', function(event){
          $(this).parent().parent().addClass('clicked');
          $(this).parent().parent().children('li').removeClass('active');
          $(this).parent().toggleClass('active');
          var val = $(this).val();
          $('.save').attr('value', val);
           $('#error-text').text('')
        })

        $body.on('click', '.vote-container .close', function(event){
          $('.vote-mobile').removeClass('visible');
          })

		$body.on('click', '.rating', function(e){
			if($('body.no-touch').length > 0 ){

			}
			else{
				if($(this).hasClass('voted')){}
				else{
        $('.vote-mobile').addClass('visible');
}
      }
			})


		$body.on('click', '.VoteBtn button', function(e){
			e.preventDefault();
			if($('body.no-touch').length > 0 || $('.vote-mobile.visible').length > 0){
				var post_id = $(this).parent().attr('data');
				var value = $(this).attr('value');
				if(value > 5){
					var value = 5
				}
              if(value){
				   $.ajax({
					   type: 'POST',
					   url: ajaxurl,
					   data: {"action": "load-content", post_id: post_id , value: value},
					   success: function(response) {
					   		var parsedResponse = JSON.parse(response);
							$('.rating').find('span').text(parsedResponse[0] + ' Röster').attr('content', parsedResponse[0]);
							$('.rating').find('h3').text(parsedResponse[1] + '/5');
							$('.rating').removeClass('hover')
						   if($('.vote-mobile.visible').length > 0){
								$('.vote-container h2').hide();
								$('.vote-container #error-text').hide();
								$('.vote-container p').text('Tack för din röst');
								$('.vote-container li p').hide();
								$('.VoteBtn').hide();
								setTimeout(function(){
									$('.vote-container h2').show();
									$('.vote-container p').text('Kul att du vill visa vad du tycker, klicka på stjärnorna och spara ditt betyg!');
									$('.vote-container #error-text').show();
									$('.vote-container li p').show();
									$('.VoteBtn').show();
									$('.vote-mobile').removeClass('visible');
							   },1500);
								$('.rating').addClass('temp');
								setTimeout(function(){
									$('.rating').removeClass('temp');
									$('.rating').addClass('voted')
								},400);
							} else {
							  $('.rating').addClass('voted')
							}
					   }
					});
				   return false;
               } else {
                 $('.vote-container ul').addClass('scale');
                 setTimeout(function(){
                   $('.vote-container ul').removeClass('scale');
                 }, 100);
                 $('#error-text').text('Välj ett betyg först.')
               }
             } else{
			   $('.vote-mobile').addClass('visible');
			 }
		    });

		$body.on('click', '#save', function(event){
			$body.scrollTop(0);
			getImageFromUrl($('.slide').children('img').attr('src'), createPDF);
			$('.recept').removeClass('savePDF');
		});

		/************* Video player ************/
		var activeVideo = "";

		$body.on('click', '#slider .close', function(event){
			$('.close').removeClass('active');
			$('#nav-btn-wrapper').removeClass('hide');
			$(this).parent().children('.video')[0].src = activeVideo;
			$('.overlay').fadeIn();
		});
		$body.on('click', '.overlay', function(event){
			$(this).hide();
			activeVideo = $(this).parent().children('.video')[0].src;
			$(this).parent().children('.video')[0].src += "autoplay=1";
			$('#nav-btn-wrapper').addClass('hide');
			$('.close').addClass('active');
			event.preventDefault();
		});
	}});

	$(window).on('scroll', function () {
		var scroll = $(window).scrollTop();
		var $ingredients = $('.ingredients');
		var $offset = $('.recept-functions-wrapper');
		// Add class when scroll has reached the ingredients element.
		if(scroll >= $ingredients.offset().top - 60) {
			$ingredients.addClass('fixed');
		}
		// Remove class when scroll has scrolled past the steps.
		if(scroll > $('.recept-content').height() || scroll < $offset.offset().top + $offset.height()) {
			$ingredients.removeClass('fixed');
		}
    });

})(jQuery, this);
