(function ($, root, undefined) {
	
	$(function () {
		
		'use strict';
		var activeVideo = "";
		
		$('body').on('click', '#slider .close', function(event){
			$('.close').removeClass('active');
			$(this).parent().children('.video')[0].src = activeVideo;
			$('.overlay').fadeIn();
		})	
		$('body').on('click', '.overlay', function(event){
				  $(this).hide();
				  activeVideo = $(this).parent().children('.video')[0].src;
				  $(this).parent().children('.video')[0].src += "&autoplay=1";
				  $('.close').addClass('active');
				  event.preventDefault();
			})
		});
})(jQuery, this);
